import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 998.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,998.000000) scale(0.100000,-0.100000)">
          <path d="M0 4990 l0 -4980 5398 0 5397 0 -3 4980 -2 4980 -5395 0 -5395 0 0
-4980z m10493 4932 c109 -44 205 -140 249 -250 l23 -57 0 -4630 0 -4630 -32
-68 c-45 -94 -119 -169 -213 -215 l-75 -37 -5046 0 -5045 0 -75 37 c-80 40
-108 62 -160 130 -45 57 -59 83 -78 143 -14 45 -16 475 -18 4580 -2 2528 1
4568 6 4616 19 190 132 333 309 390 55 17 241 18 5077 16 l5020 -2 58 -23z"/>
<path d="M5085 8479 c-414 -33 -762 -172 -1042 -416 -274 -239 -488 -551 -580
-848 -109 -352 -122 -698 -37 -995 31 -107 120 -292 181 -379 201 -281 470
-432 883 -497 131 -20 506 -24 630 -6 47 7 142 24 211 38 l127 26 76 -53 c183
-126 276 -276 276 -450 0 -215 -156 -396 -385 -444 -89 -19 -156 -19 -244 0
-218 46 -417 223 -547 488 -21 42 -41 77 -44 77 -5 0 -519 -306 -528 -314 -4
-5 46 -93 95 -168 69 -105 238 -270 358 -351 262 -174 529 -248 895 -249 314
-1 513 40 755 156 219 106 407 277 534 485 193 318 224 816 71 1128 -71 144
-238 328 -387 425 -83 54 -289 166 -518 281 -249 125 -368 191 -479 264 -130
87 -199 155 -239 240 -30 65 -32 74 -32 188 0 117 1 122 34 190 54 109 154
194 279 236 53 18 89 23 172 22 162 0 300 -48 430 -148 52 -40 75 -64 135
-136 31 -39 96 -149 127 -217 19 -42 33 -60 41 -56 7 4 89 61 182 127 94 66
187 132 207 146 21 14 38 31 38 36 0 6 -20 46 -45 89 -169 297 -448 517 -768
605 -90 24 -251 51 -311 51 -25 0 -48 5 -51 10 -4 6 16 84 44 175 28 90 51
170 51 178 0 8 -26 19 -72 30 -167 37 -351 50 -523 36z m297 -374 c184 -36
190 -41 62 -49 -418 -27 -697 -139 -943 -379 -184 -178 -297 -395 -346 -661
-24 -130 -17 -430 13 -536 55 -195 172 -368 365 -538 48 -42 163 -117 315
-204 68 -39 126 -76 129 -81 4 -7 -35 -8 -113 -4 -325 16 -559 106 -739 282
-219 214 -328 489 -327 825 1 307 85 587 249 833 129 192 279 326 467 417 178
86 334 118 578 119 139 1 187 -3 290 -24z"/>
<path d="M2619 2844 c-78 -23 -139 -110 -139 -194 1 -40 34 -102 73 -137 42
-37 115 -56 169 -44 73 17 132 70 113 101 -10 17 -21 12 -58 -25 -46 -46 -92
-48 -139 -6 -27 24 -32 36 -36 89 -9 123 22 182 96 182 39 0 92 -35 92 -60 0
-6 11 -3 25 6 l26 17 -23 25 c-28 30 -94 62 -128 61 -14 0 -46 -7 -71 -15z"/>
<path d="M6835 2853 c-52 -14 -95 -69 -95 -123 0 -28 47 -77 105 -108 39 -21
51 -33 53 -55 7 -58 -50 -77 -92 -32 -27 29 -39 31 -57 9 -12 -14 -9 -20 19
-44 89 -74 232 -21 232 85 0 54 -19 78 -94 118 -33 18 -64 40 -70 50 -17 26 5
51 46 55 28 2 40 -2 55 -21 17 -22 21 -23 38 -11 18 13 18 14 -1 35 -34 39
-88 55 -139 42z"/>
<path d="M3090 2660 l0 -190 55 0 55 0 0 85 0 85 55 0 55 0 0 -86 0 -85 53 3
52 3 3 188 2 187 -55 0 -55 0 0 -74 c0 -42 -4 -76 -10 -78 -6 -2 -31 -2 -55 0
l-45 4 0 74 0 74 -55 0 -55 0 0 -190z"/>
<path d="M3690 2660 l0 -191 51 3 51 3 0 185 0 185 -51 3 -51 3 0 -191z"/>
<path d="M4070 2660 l0 -190 50 0 49 0 3 78 3 77 69 6 c62 5 72 9 99 39 24 27
28 39 23 69 -13 82 -63 109 -203 110 l-93 1 0 -190z m173 122 c40 -35 7 -118
-45 -110 -20 3 -23 9 -26 57 -4 70 2 84 30 76 13 -4 31 -14 41 -23z"/>
<path d="M4610 2660 l0 -190 56 0 c51 0 55 2 54 23 -1 12 -2 48 -1 80 l1 57
55 0 c69 0 98 14 121 56 17 31 17 37 4 76 -22 64 -75 88 -197 88 l-93 0 0
-190z m173 122 c37 -33 10 -112 -39 -112 -28 0 -30 6 -26 80 4 57 5 60 25 54
12 -3 30 -13 40 -22z"/>
<path d="M5150 2660 l0 -190 119 0 c114 0 119 1 124 22 4 12 4 25 1 28 -3 3
-33 6 -67 7 -34 1 -64 2 -67 3 -3 0 -3 25 -2 55 l4 55 59 0 59 0 0 31 0 30
-57 -2 -58 -3 -5 47 c-4 26 -4 50 -1 52 2 3 33 5 68 5 61 0 63 1 63 25 l0 25
-120 0 -120 0 0 -190z"/>
<path d="M5650 2661 l0 -191 55 0 55 0 0 171 0 171 36 -7 c47 -8 64 -26 64
-65 0 -44 -15 -67 -50 -75 -16 -4 -30 -10 -30 -13 0 -4 16 -46 37 -94 l37 -88
53 0 c29 0 53 3 53 6 0 4 -16 38 -35 75 -19 38 -35 72 -35 77 0 4 13 14 29 23
17 10 34 31 41 53 10 31 9 42 -7 74 -26 54 -60 66 -190 70 l-113 4 0 -191z"/>
<path d="M7252 2702 c2 -120 6 -153 21 -175 51 -78 200 -80 251 -3 13 21 16
53 16 176 l0 150 -25 0 -25 0 0 -141 c0 -77 -5 -149 -10 -160 -8 -14 -21 -19
-48 -19 -66 0 -67 3 -70 170 l-3 150 -56 0 -55 0 4 -148z"/>
<path d="M7820 2660 l0 -190 98 0 c143 0 194 19 208 76 10 40 -4 71 -42 94
l-35 22 23 12 c13 6 29 24 36 40 12 23 12 34 0 64 -21 56 -61 72 -185 72
l-103 0 0 -190z m177 128 c16 -13 17 -70 1 -86 -7 -7 -27 -12 -45 -12 l-33 0
0 53 c0 30 3 57 7 61 9 9 53 -1 70 -16z m9 -165 c34 -46 6 -103 -50 -103 l-36
0 0 60 0 60 36 0 c23 0 42 -6 50 -17z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
